<template>
  <ul :data-cy="dataCyName([navGroupItem.key])" class="mb-8">
    <li
      v-if="!hideGroupLabel"
      :data-cy="dataCyName([navGroupItem.key, 'header'])"
      class="fw-bold text-uppercase mb-3"
    >
      {{ translateLabel(navGroupItem) }}
    </li>

    <navigation-sidebar-link-anchor
      v-for="navItem in navGroupItem.items"
      :key="
        navItem.data.url != null
          ? `${navItem.data.url}__${navItem.key}`
          : `${navItem.label}__${navItem.key}`
      "
      :nav-item="navItem"
    />
  </ul>
</template>

<script lang="ts">
import dataCyName from '~/helpers/cypress';
import type { SideNavigationGroupItem } from '../../types';
import { useLabelTranslation } from '../features/utils';

export default defineComponent({
  name: 'SidebarLinkGroup',
  props: {
    navGroupItem: {
      type: Object as PropType<SideNavigationGroupItem>,
      required: true,
    },
    hideGroupLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const translateLabel = useLabelTranslation();

    return {
      translateLabel,
      dataCyName,
    };
  },
});
</script>
