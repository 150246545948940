import {
  faCube,
  faGamepad,
  faMicrophone,
} from '@fortawesome/pro-solid-svg-icons';
import { type SideNavigation, SideNavigationItemTypes } from '../../types';
import useNavigationBasePages from './useNavigationBasePages';
import useNavigationProductSlugs from './useNavigationProductSlugs';

export default async () => {
  const { locale } = useI18n();
  const firstLevelMenu = ref<SideNavigation>(null);
  const { indexUrl } = useNavigationBasePages();
  const { navSlugs } = await useNavigationProductSlugs();

  const productMenuData = computed(() => {
    const productMenuGroup = [
      {
        key: `${locale.value}__gameserver`,
        label: 'general/menu/item/game_servers',
        translate: true,
        icon: faGamepad,
        type: SideNavigationItemTypes.NUXT,
        data: {
          url: indexUrl.value,
        },
      },
    ];

    if (
      navSlugs.value.minecraftSlug != null &&
      navSlugs.value.minecraftSlug.length > 0
    ) {
      productMenuGroup.push({
        key: `${locale.value}__minecraft`,
        label: 'general/menu/item/minecraft',
        translate: true,
        icon: faCube,
        type: SideNavigationItemTypes.NUXT,
        data: {
          url: `/${locale.value}/${navSlugs.value.minecraftSlug}`,
        },
      });
    }

    if (
      navSlugs.value.teamspeakSlug != null &&
      navSlugs.value.teamspeakSlug.length > 0
    ) {
      productMenuGroup.push({
        key: `${locale.value}__teamspeak3`,
        label: 'general/menu/item/teamspeak3',
        translate: true,
        icon: faMicrophone,
        type: SideNavigationItemTypes.NUXT,
        data: {
          url: `/${locale.value}/${navSlugs.value.teamspeakSlug}`,
        },
      });
    }

    return {
      key: `${locale.value}__products`,
      label: 'general/menu/block/products',
      translate: true,
      items: productMenuGroup,
    };
  });

  return {
    firstLevelMenu,
    productMenuData,
  };
};
