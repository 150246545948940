import type {
  GetNavigationSlugsQuery,
  GetNavigationSlugsQueryVariables,
} from '~/apollo/types/types';
import { GetNavigationSlugsDocument } from '~/apollo/types/types';

export default async () => {
  const { $sentry } = useNuxtApp();
  const { locale } = useI18n();

  const { refresh, data: navSlugs } = await useAsyncData(
    `gp-navigation-product-slugs-${locale.value}`,
    async () => {
      try {
        const { data: articlesData } = await useLegacyApollo<
          GetNavigationSlugsQuery,
          GetNavigationSlugsQueryVariables
        >(GetNavigationSlugsDocument, {
          locale: locale.value,
          productKeys: ['minecraft', 'teamspeak-3'],
        });

        return {
          minecraftSlug: articlesData.products.find(
            (p) => p.productKey === 'minecraft',
          )?.slug,
          teamspeakSlug: articlesData.products.find(
            (p) => p.productKey === 'teamspeak-3',
          )?.slug,
        };
      } catch (error) {
        $sentry.captureException(error);
      }

      return {
        minecraftSlug: null,
        teamspeakSlug: null,
      };
    },
  );

  watch(locale, () => {
    refresh();
  });

  return {
    navSlugs,
  };
};
