import { faNewspaper } from '@fortawesome/pro-regular-svg-icons';
import {
  faBookOpen,
  faHeadset,
  faList,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type SideNavigationGroupItem,
  type SideNavigationItem,
  SideNavigationItemTypes,
} from '../../types';

export default () => {
  const { locale } = useI18n();
  const { region } = useRegion();

  const supportMenu = computed<SideNavigationItem>(() => ({
    key: `${locale.value}__support`,
    label: 'general/menu/item/support',
    translate: true,
    icon: faHeadset,
    type: SideNavigationItemTypes.LINK,
    data: {
      url: `/${region.value}/support`,
    },
  }));

  /**
   * returns part of the homepage menu (community blog)
   * @param router
   */
  const communityMenuData = computed<SideNavigationGroupItem>(() => {
    const newsMenu = {
      key: `${locale.value}__news`,
      label: 'general/menu/item/news',
      translate: true,
      icon: faNewspaper,
      type: SideNavigationItemTypes.NUXT,
      data: {
        url: `/${locale.value}/news`,
      },
    };

    const wikiForumMenus = [
      {
        key: `${locale.value}__wiki`,
        label: 'general/menu/item/wiki',
        translate: true,
        icon: faBookOpen,
        type: SideNavigationItemTypes.EXTERNAL,
        data: {
          url: `/wiki/${locale.value}`,
        },
      },
      {
        key: `${locale.value}__forum`,
        label: 'general/menu/item/forum',
        translate: true,
        icon: faList,
        type: SideNavigationItemTypes.EXTERNAL,
        data: {
          url: 'https://forum.g-portal.com/',
        },
      },
    ];

    const items = [newsMenu, ...wikiForumMenus];
    return {
      key: `${locale.value}__community`,
      label: 'general/menu/block/community',
      translate: true,
      items,
    };
  });

  return {
    supportMenu,
    communityMenuData,
  };
};
